.icon-button {
  @apply text-black dark:text-white;
  display: inline-flex;
  align-items: center;
  padding: 0;
  color: var(--primary-text-color);
  border: 0;
  background: transparent;
  cursor: pointer;
  transition: 100ms ease-in;
  opacity: 0.4;

  i.fa {
    margin: 0;
  }

  &__text {
    padding-left: 2px;
  }

  &:hover,
  &:active,
  &:focus {
    opacity: 0.6;
    transition: color 200ms ease-out;
  }

  &.disabled {
    opacity: 0.2;
    cursor: default;
  }

  &.active {
    color: var(--highlight-text-color);
    opacity: 1;
  }

  &::-moz-focus-inner {
    border: 0;
  }

  &::-moz-focus-inner,
  &:focus,
  &:active {
    outline: 0 !important;
  }

  &.inverted {
    color: var(--primary-text-color--faint);
    opacity: 1;

    &:hover,
    &:active,
    &:focus {
      color: var(--primary-text-color);
    }

    &.disabled {
      color: var(--primary-text-color--faint);
    }

    &.active {
      color: var(--highlight-text-color);

      &.disabled {
        color: var(--highlight-text-color);
      }
    }
  }

  &.overlayed {
    box-sizing: content-box;
    background: var(--foreground-color);
    color: var(--primary-text-color--faint);
    border-radius: 6px;
    padding: 2px;
    opacity: 1;

    > div {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &:hover {
      background: var(--background-color);
    }
  }
}

.text-icon-button {
  color: var(--primary-text-color--faint);
  border: 0;
  background: transparent;
  cursor: pointer;
  font-weight: 600;
  font-size: 11px;
  padding: 0 3px;
  line-height: 27px;
  outline: 0;
  transition: color 100ms ease-in;

  &:hover,
  &:active,
  &:focus {
    color: var(--primary-text-color);
    transition: color 200ms ease-out;
  }

  &.disabled {
    color: var(--primary-text-color--faint);
    cursor: default;
  }

  &.active {
    color: var(--highlight-text-color);
  }

  &::-moz-focus-inner {
    border: 0;
  }

  &::-moz-focus-inner,
  &:focus,
  &:active {
    outline: 0 !important;
  }
}

.invisible {
  font-size: 0 !important;
  line-height: 0 !important;
  display: inline-block;
  width: 0;
  height: 0;
  position: absolute;

  img,
  svg {
    margin: 0 !important;
    border: 0 !important;
    padding: 0 !important;
    width: 0 !important;
    height: 0 !important;
  }
}

.react-datepicker-popper {
  z-index: 9999 !important;
}

.ellipsis::after { content: "…"; }

.timeline-compose-block {
  @include standard-panel;
  display: flex;
  align-items: flex-start;
  padding: 20px;
  margin-bottom: 10px;

  .compose-form {
    flex: 1 1;
    padding: 0 0 0 20px !important;
    position: relative;

    @media (max-width: 405px) {
      padding: 0 !important;
    }
  }

  &__avatar {
    display: block;
    border-radius: 50%;
    @media (max-width: 405px) { display: none; }
  }
}

.no-reduce-motion .spoiler-input {
  transition: height 0.4s ease, opacity 0.4s ease;
}

.emojione {
  display: inline-block;
  font-size: inherit;
  vertical-align: middle;
  object-fit: contain;
  margin: -0.2ex 0.15em 0.2ex;
  width: 16px;
  height: 16px;

  img {
    width: auto;
  }
}

.domain {
  padding: 10px;
  border-bottom: 1px solid var(--brand-color--med);

  .domain__domain-name {
    flex: 1 1 auto;
    display: block;
    color: var(--primary-text-color);
    text-decoration: none;
    font-size: 14px;
    font-weight: 500;
  }

  &__buttons .svg-icon {
    height: 18px;
    width: 18px;
  }
}

article:last-child > .domain {
  border-bottom: none;
}

.domain__wrapper {
  display: flex;
}

.image-loader {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .image-loader__preview-canvas {
    max-width: $media-modal-media-max-width;
    max-height: $media-modal-media-max-height;
    background: url('../assets/images/void.png') repeat;
    object-fit: contain;
  }

  &.image-loader--amorphous .image-loader__preview-canvas {
    display: none;
  }
}

.zoomable-image {
  @apply relative w-full h-full flex items-center justify-center;

  img {
    @apply w-auto h-auto object-contain shadow-2xl;
    max-width: $media-modal-media-max-width;
    max-height: $media-modal-media-max-height;
  }
}

.react-swipeable-view-container {
  &,
  .columns-area,
  .column {
    height: 100%;
  }
}

.react-swipeable-view-container > * {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.ui {
  display: block;
  width: 100%;
  padding: 0 0 calc(var(--thumb-navigation-height) + 86px);

  .bg-shape-container {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }

  .page {
    display: flex;
    flex-direction: column;
    width: 100%;

    &__top {
      @include standard-panel-shadow;
      display: flex;
      width: 100%;
      height: auto;
      z-index: 1000;
      background: var(--foreground-color);

      @media (min-width: 896px) {
        top: -290px;
        position: sticky;
      }
    }

    &__columns {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
    }
  }
}

.floating-action-button {
  @apply z-40 lg:hidden transition-all fixed bottom-24 right-4 p-4 text-white bg-accent-300 hover:bg-accent-500 rounded-full;

  .svg-icon {
    @apply w-6 h-6;

    svg {
      @apply w-6; // iOS fix
      stroke-width: 1.5px;
    }
  }
}

.icon-with-badge__badge {
  @include font-size(14);
  @include line-height(14);
  position: absolute;
  box-sizing: border-box;
  left: -10px;
  top: 3px;
  min-width: 16px;
  height: 16px;
  padding: 1px 3px 0;
  border-radius: 8px;
  text-align: center;
  color: #fff;
  background: var(--accent-color);

  @media screen and (max-width: 895px) {
    top: 0;
  }
}

.slist {
  &--flex {
    display: flex;
    flex-direction: column;
  }

  &__append {
    flex: 1 1 auto;
    position: relative;
    padding: 30px 15px;
  }
}

.setting-text {
  color: var(--primary-text-color--faint);
  background: transparent;
  border: 0;
  border-bottom: 2px solid var(--brand-color);
  box-sizing: border-box;
  display: block;
  font-family: inherit;
  margin-bottom: 10px;
  padding: 7px 0;
  width: 100%;

  &:focus,
  &:active {
    color: var(--primary-text-color);
    border-bottom-color: var(--highlight-text-color);
  }

  @media screen and (max-width: 600px) {
    font-size: 16px;
  }
}

.morefollows-indicator {
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  color: var(--primary-text-color);
  background: var(--brand-color--med);
  cursor: default;
  display: flex;
  flex: 1 1 auto;
  align-items: center;
  justify-content: center;
  padding: 20px;

  & > div {
    width: 100%;
    background: transparent;
    padding-top: 0;
  }

  &__label {
    strong {
      display: block;
      margin-bottom: 10px;
      color: var(--primary-text-color);
    }
  }
}

.text-btn {
  display: inline-block;
  padding: 0;
  font-family: inherit;
  font-size: inherit;
  color: inherit;
  border: 0;
  background: transparent;
  cursor: pointer;
}

.account--panel__button {
  flex: 1 1 auto;
  text-align: center;
}

.emoji-button {
  display: block;
  font-size: 24px;
  line-height: 24px;
  margin-left: 2px;
  width: 24px;
  outline: 0;
  cursor: pointer;

  &:active,
  &:focus {
    outline: 0 !important;
  }

  img {
    filter: grayscale(100%);
    opacity: 0.8;
    display: block;
    margin: 0;
    width: 22px;
    height: 22px;
    margin-top: 2px;
  }

  &:hover,
  &:active,
  &:focus {
    img {
      opacity: 1;
      filter: none;
    }
  }
}

.attachment-list {
  display: flex;
  font-size: 14px;
  border: 1px solid var(--brand-color--med);
  border-radius: 4px;
  margin-top: 14px;
  overflow: hidden;

  &__icon {
    flex: 0 0 auto;
    color: var(--primary-text-color);
    padding: 8px 18px;
    cursor: default;
    border-right: 1px solid var(--brand-color--med);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 26px;

    .fa {
      display: block;
    }
  }

  &__list {
    list-style: none;
    padding: 4px 0;
    padding-left: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    white-space: nowrap;

    li {
      display: block;
      padding: 4px 0;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    a {
      text-decoration: none;
      color: var(--primary-text-color);
      font-weight: 500;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &.compact {
    border: 0;
    margin-top: 4px;

    .attachment-list__list {
      padding: 0;
      display: block;
    }

    .fa {
      color: var(--primary-text-color);
    }
  }
}

.filter-bar {
  cursor: default;
  display: flex;
  flex-shrink: 0;

  button {
    border: 0;
    margin: 0;
  }

  button,
  a {
    @apply block flex-1 text-gray-500 py-4 text-sm font-semibold text-center relative no-underline;

    &:active,
    &:focus,
    &:hover,
    &.active {
      @apply text-gray-900;
    }

    .svg-icon {
      width: 18px;
      height: 18px;
      margin: 0 auto;
    }
  }
}

.filter-bar {
  @apply relative;

  &__active {
    @apply absolute h-[3px] bottom-0 bg-primary-600;
  }

  &__bottom {
    @apply absolute h-[3px] w-full bottom-0 bg-primary-200;
  }
}

.no-reduce-motion .filter-bar__active {
  transition: all 0.3s;
}

.reaction__filter-bar {
  overflow-x: auto;
  overflow-y: hidden;

  button,
  a {
    flex: unset;
    padding: 15px 24px;
    min-width: max-content;
  }
}

::-webkit-scrollbar-thumb {
  border-radius: 0;
}

@keyframes flicker {
  0% { opacity: 1; }
  30% { opacity: 0.75; }
  100% { opacity: 1; }
}

.page__top + .page__columns .columns-area {
  @media screen and (max-width: 580px) {
    padding-top: 10px;
  }
}

.text-muted {
  color: var(--gray-500);
}

.mb-10 {
  margin-bottom: 10px;
}
